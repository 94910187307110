<template>
  <div>
    <NavTitle class="mb16" title="防骗指南" :showBack="false">
      <template #right>
        <div class="flex between-center flex-1">
          <div class="btn-add" @click="isAdd = true">
            <img src="@/assets/images/add.png">
            <span>添加防骗指南</span>
          </div>
          <div class="input-row">
            <el-input class="input" placeholder="请输入关键词" clearable v-model="seachWord" @change="getGuideList"></el-input>
            <el-button slot="append" type="primary" icon="el-icon-search"  @click="getGuideList"></el-button>
          </div>
        </div>
      </template>
    </NavTitle>
    <div class="ques-list ml20 mr20">
      <div v-if="isAdd" class="ques-item">
        <div class="flex items-center">
          <el-input class="flex-1" placeholder="请输入标题" v-model="editForm.title"></el-input>
          <div class="flex items-center ml16">
            <el-button size="mini" plain @click="handleCancel">取消</el-button>
            <el-button size="mini" type="primary" @click="handleAction(false)">确定</el-button>
          </div>
        </div>
        <el-input class="mt8" placeholder="请输入内容" v-model="editForm.content"></el-input>
      </div>
      <Draggable v-model="guideList" @end="handleDragEnd">
        <div class="ques-item" v-for="(item, index) in guideList" :key="index">
          <template v-if="editId != item.gid">
            <div class="flex between-center">
              <div class="title">{{item.title}}</div>
              <div class="btn-group">
                <img src="@/assets/images/icon-edit-line2.png" @click="toEdit(item)">
                <Popover title="确认删除该条防骗指南吗？" @confirm="handleDelete(item.gid)">
                  <img slot="reference" src="@/assets/images/icon-delete-gray.png">
                </Popover>
                <img src="@/assets/images/icon-bread.png">
              </div>
            </div>
            <div class="mt16">{{item.content}}</div>
          </template>
          <template v-else>
            <div class="flex items-center">
              <el-input class="flex-1" placeholder="请输入标题" v-model="editForm.title"></el-input>
              <div class="flex items-center ml16">
                <el-button size="mini" plain @click="handleCancel">取消</el-button>
                <el-button size="mini" type="primary" @click="handleAction(true)">确定</el-button>
              </div>
            </div>
            <el-input class="mt8" placeholder="请输入内容" v-model="editForm.content"></el-input>
          </template>
        </div>
      </Draggable>
      <el-empty v-if="!guideList || guideList.length == 0" description="暂无相关数据" :image="require('@/assets/images/nothing-empty.png')"></el-empty>
    </div>
  </div>
</template>

<script>
import NavTitle from '@/components/NavTitle'
import Pagination from '@/components/Pagination'
import Popover from '@/components/Popover'
import Draggable from 'vuedraggable'
import { queryAdminGuidesApi, editGuideApi } from '@/api/admin.js'
export default {
  name: 'Question',
  components: {
    NavTitle,
    Pagination,
    Popover,
    Draggable
  },
  data() {
    return {
      seachWord: '',
      editId: '',
      guideList: [],
      editForm: {
        title: '',
        content: ''
      },
      isAdd: false
    }
  },
  created() {
    this.getGuideList()
  },
  methods: {
    resetForm() {
      this.editForm = {
        title: '',
        content: ''
      }
    },
    getGuideList() {
      const formData = {
        type: 2,
        searchText: this.seachWord.trim()
      }
      queryAdminGuidesApi(formData).then(res => {
        console.log('防骗指南', res)
        this.guideList = res.data.guides || []
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
    },
    handleAction(isEdit) {
      // 1-新增 2-修改 3-删除 4-排序
      if(!this.editForm.title.trim()) return this.$tips({message: '请输入标题', type:'warning'})
      if(!this.editForm.content.trim()) return this.$tips({message: '请输入内容', type:'warning'})
      const formData = {
        type: 2,
        act: isEdit ? 2 : 1,
        guide: {
          content: this.editForm.content.trim(),
          title: this.editForm.title.trim(),
        }
      }
      if(isEdit) {
        Object.assign(formData.guide, { gid: this.editId })
      }
      console.log('formData', formData)
      editGuideApi(formData).then(res => {
        console.log('结果', res)
        this.$tips({message: res.msg, type: 'success'})
        this.isAdd = false
        this.editId = ''
        this.resetForm()
        this.getGuideList()
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
    },
    toEdit(info) {
      this.editId = info.gid
      this.editForm.title = info.title
      this.editForm.content = info.content
    },
    handleDelete(gid) {
      console.log('删除', gid)
      editGuideApi({
        type: 2,
        act: 3,
        guide: {
          gid
        }
      }).then(res => {
        this.$tips({message: res.msg, type: 'success'})
        this.getGuideList()
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
    },
    handleCancel() {
      this.isAdd = false
      this.editId = ''
      this.resetForm()
    },
    handleDragEnd(e) {
      console.log('end', e)
      const gid = this.guideList[e.newIndex].gid
      const exchangeGid = this.guideList[e.oldIndex].gid
      const formData = {
        type: 2,
        act: 4,
        exchangeGid,
        guide: {
          gid
        }
      }
      console.log('formData', formData)
      editGuideApi(formData).then(res => {
        this.$tips({message: res.msg, type: 'success'})
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.btn-add {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 4px;
  color: var(--theme-color);
  background-color: #DCFFFD;
  font-size: 14px;
  cursor: pointer;
  img {
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }
}
.input-row {
  width: 380px;
  display: flex;
  align-items: center;
}
.ques-list {
  background: #fff;
  border-radius: 8px;
  padding: 24px;
  .ques-item {
    padding: 24px;
    font-size: 14px;
    color: #505E5C;
    border-radius: 8px;
    &:hover {
      box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.10);
    }
    .title {
      position: relative;
      font-weight: bold;
      color: #000000;
      padding-left: 20px;
      margin-bottom: 8px;
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background-color: var(--theme-color);
      }
    }
    .btn-group {
      display: flex;
      align-items: center;
      img {
        width: 16px;
        height: 16px;
        margin-left: 12px;
        cursor: pointer;
      }
    }
  }
}
</style>